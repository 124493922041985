.searchpage-columns {
    display: flex;
    width: calc(100vw - 4.1rem)
}

.searchpage-columns-searchbar {
    width: 25%;
    padding-top: .8rem;
    margin-top: 1rem;
    padding-bottom: .5rem
}

.searchpage-columns-results {
    width: 75%
}

.searchpage-searchbar {
    position: sticky;
    top: .8rem;
    background-color: var(--background-color)
}

@media (max-width: 858px) {

    .searchpage-columns {
        display: block;
        width: 100%
    }

    .searchpage-columns-searchbar {
        width: calc(100% - .8rem);
        z-index: 9999999;
        margin-left: .8rem;
        
    }

    .searchpage-columns-results {
        width: 100%
    }

    .searchpage-searchbar {
        top: 0
    }

}