.content-slider {
    padding: var(--default-padding);
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 0;
    scroll-behavior: smooth;
}

.content-slider__item {
    margin-right: 1.1rem;
    height: fit-content;
    max-width: 140px
}

.content-slider__item:last-child {
    padding-right: 30px
}

.content-slider__category-title {
    font-size: 1.4rem;
    padding: var(--default-padding);
    padding-bottom: 0;
    padding-top: 1.8rem
}

.content-slider__item-date {
    color: grey;
    margin-top: .3rem;
    font-size: .9rem
}

.content-slider__items-container {
    margin: 1rem 0;
    display: flex
}

/*.container-slider__items-container-border-fade::before {
    content: '';
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,0) 0%, #fff 100%);
    will-change: opacity;
    pointer-events: none;
} */

.content-slider__item > img {
    width: 140px;
    height: 210px;
    border-radius: 12px;
    transition: all .2s ease-in-out;
    z-index: 10
}

.content-slider__item > img:hover {
    transform: scale(1.1);
    cursor: pointer
}

.content-slider__item-text {
    margin-top: .5rem;
}

.content-slider__item-rating {
    background-color: grey;
    padding: .1rem .4rem;
    border-radius: 20px;
    font-size: .8rem;
    padding-left: .2rem;
    color: white
}

.content-slider__item-title {
    font-size: .9rem;
    cursor: pointer;
    margin-top: .4rem
}

.content-slider__next-arrow {
    position: sticky;
    left: calc(100% - 1.3rem);
    top: 9rem;
    background: var(--main-color);
    padding: .6rem;
    border-radius: 6px;
    z-index: 99999;
    cursor: pointer;
    transition: all .2s ease-in-out
}

.content-slider__next-arrow:hover {
    transform: scale(1.1);
}

.content-slider__previous-arrow {
    position: sticky;
    right: calc(100% - 1.3rem);
    top: 9rem;
    background: var(--main-color);
    padding: .6rem;
    border-radius: 6px;
    z-index: 99999;
    cursor: pointer;
    transition: all .2s ease-in-out
}

.content-slider__previous-arrow:hover {
    transform: scale(1.1);
}

/* Para BackdropContentSlider */

.backdrop-content-slider__image {
    height: 160px !important
}

