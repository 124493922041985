:root {
    --text-color: #FFF;
    --background-color: #121212;
    --default-padding: .8rem 1rem;
    --default-padding-mobile: 2rem 1.1rem;
    --main-color: rgb(138,43,226);
    --screen-size-large: 952px;
    --screen-size-medium: 858px;
    --footer-margin: 5rem

}

body {
    background-color: var(--background-color);
    font-family: 'Roboto', sans-serif;
    font-display: swap;
    color: var(--background-color)
}

h3 {
    font-size: 1.4rem
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    color: var(--main-color);
    font-family: 'Roboto', sans-serif;
}

input, textarea {
    font-family: 'Roboto', sans-serif;
}

button {
    cursor: pointer;
    border: none;
    font-family: 'Roboto', sans-serif;
}

.footer-margin {
    margin-bottom: var(--footer-margin)
}

.info-page {
    min-height: 30vh;
    padding: 3rem
}

.info-page h1 {
    margin-bottom: 1rem
}


.homepage-gradient-background-wtob {
    /*background: rgb(245,245,245);
    background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(18,18,18,1) 0%) */
}


/* Paddings por Default para Componentes */
.default-padding {
    padding: 1rem 3rem
}

.short-padding {
    padding: 0 3rem
}

@media (max-width: 952px) {
    .default-padding {
        padding: 1rem 2rem
    }

    .short-padding {
        padding: 0 2rem
    }
}

@media (max-width: 858px) {
    .default-padding {
        padding: 0
    }

    .short-padding {
        padding: 0
    }
}



/* Horizontally Scrollbar Style para PC */

@media (min-width: 858px) {

    /* Scrollbar Dark (default) */
    .scrollbar-horizontal::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: black;
        border-radius: 10px;
    }

    .scrollbar-horizontal::-webkit-scrollbar
    {
        width: 900px;
        z-index: 999999;
        background-color: black;
        height: 10px;
        scroll-behavior: smooth
    }

    .scrollbar-horizontal::-webkit-scrollbar-thumb
    {
        border-radius: 12px;
        background-color: var(--main-color)
    }

    .scrollbar-horizontal::-webkit-scrollbar-button {
        /*width: 20px; 
        background-color: black;
        border-radius: 10px */
    }

    /* Scrollbar Light (para fondos claros) */

    .scrollbar-horizontal-light::-webkit-scrollbar-track
    {
        background-color: #eee;
        border-radius: 10px;
    }

    .scrollbar-horizontal-light::-webkit-scrollbar
    {
        width: 900px;
        z-index: 999999;
        background-color: #eee;
        height: 10px;
        scroll-behavior: smooth
    }

    .scrollbar-horizontal-light::-webkit-scrollbar-thumb
    {
        border-radius: 12px;
        background-color: var(--main-color)
    }

    .scrollbar-horizontal-light::-webkit-scrollbar-button {
        /*width: 20px; 
        background-color: #eee */
    }
}
