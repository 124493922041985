.contact-two-columns-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

#contact-form {
    display: block
}

@media (min-width: 858px) {
    #contact-form {
        width: 40%
    }
}

#contact-form > input, textarea {
    width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none
}

#contact-form > input[type=submit] {
  width: 100%;
  background-color: black;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#contact-form > input[type=submit]:hover {
  background-color: var(--main-color);
}

.contact-text {
    padding-right: 5rem;
    padding-bottom: 2rem
}

@media (max-width: 858px) {

    .contact-two-columns-div {
        display: block
    }

}