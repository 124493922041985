.movie-crew-page-header {
    margin-top: 1rem;
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-bottom: 2rem
}

.movie-crew-page-header > img {
    width: 60px;
    margin-right: 1rem;
    border-radius: 6px
}

.movie-crew-page-header-goback {
    color: var(--main-color);
    background-color: transparent;
    padding: 0;
    font-weight: 600;
    font-size: 1rem;
    display: 'flex';
    alignItems: 'center';
    flexWrap: 'wrap'
}

.movie-crew-page-header-goback:hover {
    color: grey
}

.movie-crew-page-columns {
    display: flex;
    justify-content: space-between
}

.movie-crew-page-columns-cast-item {
    display: flex;
    vertical-align: middle;
    align-items: center;
    background-color: #eee;
    padding: .2rem;
    padding-right: .5rem;
    margin-bottom: .5rem;
    border-radius: 6px
}

.movie-crew-page-columns-cast-item > img {
    width: 60px;
    border-radius: 6px;
    margin-right: .8rem
}

.movie-crew-page-category {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding-top: 1rem
}

@media (max-width: 858px) {
    .movie-crew-page-columns {
        display: block
    }

    #movie-crew-page {
        padding: var(--default-padding-mobile)
    }
}