#hero-searchbar {
    background-color: var(--main-color);
    background-size: cover;
    background-position: center;
    box-shadow:inset 0 0 0 2000px rgba(138,43,226, 0.5)
}

#hero-searchbar-searchbar {
    display: flex
}

.hero-searchbar-container {
    padding: 4rem;
    font-size: 2.5rem;
    color: white
}

.hero-searchbar-text {
    font-size: 1.8rem;
    margin-top: .5rem;
    margin-bottom: 2rem
}

.hero-searchbar-search {
    display: flex;
    margin-top: 3rem;
}

.hero-searchbar-search-reverse {
    flex-direction: row-reverse !important;
}

.hero-searchbar-searchbar {
    width: 75%;
    height: 2.5rem;
    border-radius: 26px 0 0 26px;
    outline: none;
    border: transparent;
    padding: 4px 20px;
    font-size: 1.1rem
}

.hero-searchbar-button  {
    min-width: 25%;
    background-color: black;
    color: white;
    font-size: 1.3rem;
    outline: none;
    border: transparent;
    border-radius: 0 26px 26px 0 !important;
    /*margin-left: -10%; */
    cursor: pointer;
    transition: all .2s ease-in-out;
    height: 2.5rem
}

.hero-searchbar-button:hover {
    background-color: #111;
    color: white;
    transform: scale(1.1);
}

@media (max-width: 952px) {

    .hero-searchbar-container {
        padding: 3rem 2rem
    }

    .hero-searchbar-searchbar {
        width: 70%
    }
}