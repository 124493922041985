#search-movie-field-form {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.search-movie-field-div-results {
    background-color: white;
    color: black;
    width: 50vw;
    min-width: 50vw;
    word-break: break-all;
    overflow: hidden;
    border-radius: 0 0 6px 6px;
    margin-top: .8rem;
    font-size: 1rem;
    z-index: 9999
}

.search-movie-field-mediatype {
    color: grey;
    font-size: .8rem;
    margin-top: .2rem
}

@media (max-width: 858px) {

    .search-movie-field-div-results {
        width: 100vw;
        left: 0;
        position: absolute
    }

}

.search-movie-field-div-results-item {
    padding: .4rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center
}

.search-movie-field-div-results-item > img {
    width: 40px;
    padding-right: 10px
}

.search-movie-field-div-results-item:hover {
    background-color: #eee
}