.header {
    background: black;
    /* background: linear-gradient(39deg, rgba(138,43,226,1) 49%, rgba(0,103,255,0.7796160700608368) 100%); */ 
    height: 2rem;
    padding: .8rem;
    display: flex;
    justify-content: space-between;
    
}

.header__left > img {
    width: 160px;
    cursor: pointer
}

.header__left__menu-state-icon {
    color: white;
    float: left;
    cursor: pointer;
    margin-right: 10px
}

.header__left > input, label {
    display: none
}



.header__left, .header__center, .header__right {
    vertical-align: middle;
    display: flex;
    z-index: 500
}

.header__center {
    margin: auto;
    margin-left: 0
}

.header__center__menu-item {
    list-style: none;
    text-align: center;
    display: inline
}

.header__center__menu-item a {
    padding: .6rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: var(--text-color)
}

.header__center__menu-item a:hover {
    color: black;
    background-color: whitesmoke;
    transition: .2s;
    border-radius: 6px
}

.header__right__search-input {
    border-radius: 0px 6px 6px 0px;
    outline-style: none;
    outline-color: transparent;
    border: transparent;
    padding: .35rem .5rem
}

.header__right__search-button {
    background-color: white;
    border: transparent;
    border-radius: 6px 0px 0px 6px;
    color: grey;
    outline: none;
    padding: .2rem .2rem
}

@media (max-width: 952px) {
    
    .header__left > img {
        width: 100px;
        height: auto
    }

}

@media (max-width: 858px) {
    
    .header__left__menu-state-icon {
        display: block
    }

    .header__center > ul {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: var(--main-color);
        padding-top: 4.5rem;
        left: -101%;
        padding-left: 0;
        margin-bottom: 20px;
        top: 0;
        transition: all .5s
    }

    .header__left {
        z-index: 1000
    }

    .header__center__menu-item {
        display: block;
        line-height: 2rem
    }

    .header__right__search-input {
        width: 28vw
    }

    .header__center__menu-item a:hover {
        color: black;
        background-color: transparent;
        transition: .2s;
        /*border-radius: 6px */
    }


}