#movie-main-page {
    background-color: white;
    color: var(--background-color)
}

.movie-main-page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: white
}

.movie-main-page-container-column1 {
    flex-basis: 80%;
    width: 70%
}

.movie-main-page-container-column2 {
    flex-basis: 20%;
    width: 30%;
    padding: 3rem 1rem;
    padding-left: 0
}

@media (max-width: 952px) {

}

@media (max-width: 858px) {

    .movie-main-page-container {
        display: block
    }

    .movie-main-page-container-column1 {
        width: 100%
    }

    .movie-main-page-container-column2 {
        width: 90%;
        padding: var(--default-padding-mobile)
    }

    
    
}