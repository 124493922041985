.movie-main-page {
    min-height: 40vh;
}

#movie-main-page__backdrop {
    min-height: 40vh;
    background-size: cover;
    background-position: center;
    background-color: var(--background-color)
}

.movie-main-page__backdrop-for-mobile {
    display: none
}

#movie-main-page__backdrop-overlay {
    min-height: 40vh
}

#movie-main-page__backdrop-overlay-for-mobile {
    display: none
}

.movie-main-page__header-columns {
    display: flex;
    padding: 3rem;
    min-height: 40vh
}

.movie-main-page__header-columns-poster > img {
    border-radius: 12px;
    /*min-height: 60vh; */
    width: 300px;
}

.movie-main-page__header-columns-info {
    padding: 0 2rem;
    color: white
}

.movie-main-page__header-columns-info > h1 {
    font-weight: 600
}

.movie-main-page__header-columns-info > h3 {
    font-weight: 400;
    padding-top: .5rem;
    padding-bottom: 1rem
}

.movie-main-page__header-columns-info > p {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1.5rem
}

.movie-main-page__header-columns-info > p > span {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.3rem;
}

.movie-main-page__header-columns-info__bookmarks {
    display: flex;
    justify-content: space-between;
}

.movie-main-page__header-columns-info__bookmarks > h5 {
    font-weight: 400
}

.movie-main-page__header-columns-info__bookmarks > button, .movie-main-page-header-columns-info__bookmarks-button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    padding: .7rem 1rem;
    background-color: var(--main-color);
    border-radius: 8px
}


.movie-main-page__header-columns-info__bookmarks > button:hover {
    background-color: black;
    color: white
}


/*Sobreescribir el color de las clases de MaterialUI */
.MuiCircularProgress-colorPrimary {
    color: var(--main-color)!important
}

.MuiTypography-caption {
    font-size: 1rem !important;
    font-weight: 600 !important;
}

.MuiCircularProgress-root, .MuiCircularProgress-colorPrimary, .MuiCircularProgress-determinate {
    height: 60px !important;
    width: 60px !important
}

.MuiIconButton-root {
    padding: 2px !important;
    color: var(--text-color) !important;
}

.MuiSvgIcon-root {
    vertical-align: middle !important;
    font-size: 1.3rem !important
}

@media (max-width: 952px) {
    
    .movie-main-page__header-columns {
        padding: 2rem
    }
}

@media (max-width: 858px) {

    .movie-main-page__header-columns-poster {
        display: none
    }

    .movie-main-page__header-columns-info {
        padding: 0
    }

    .movie-main-page__header-columns {
        padding: 2rem 1.1rem;
        min-height: 0
    }

    #movie-main-page__backdrop {
        background-image: none!important;
        min-height: 0
    }

    #movie-main-page__backdrop-for-mobile {
        display: block;
        background-size: cover;
        background-color: var(--background-color)
        /* background-position: center;
        background-position: calc((((100vw / 2.222222) - 10px) / 1.5) / 2) 0 */
    }

    #movie-main-page__backdrop-overlay-for-mobile {
        display: block
    }

    #movie-main-page__backdrop-overlay-for-mobile > img {
        margin-top: 20px;
        margin-left: 20px;
        width: calc(((100vw / 2.222222) - 40px) / 1.5);
        min-width: calc(((100vw / 2.222222) - 30px) / 1.5);
        height: calc((100vw / 2.222222) - 40px);
        min-height: calc((100vw / 2.222222) - 30px);
        border-radius: 12px;
        margin-bottom: 10px
    }

    #movie-main-page__backdrop-overlay {
        min-height: 0
    }

    .movie-main-page__header-columns-info > h1 {
        font-size: 1.5rem
    }

    .movie-main-page__header-columns-info > h3 {
        font-size: 1rem
    }

    .movie-main-page__header-columns-info > p {
        font-size: 1rem
    }
}

.read-more-button {
    font-weight: 600;
    margin-top: .7rem;
    cursor: pointer;
    color: var(--main-color)
}