#footer {
    background-color: var(--main-color);
    margin-top: 5rem;
    color: white;
    padding: 2rem 3rem
}

.footer-title {
    font-size: 1rem;
    margin-bottom: 1rem
}

.footer-content-list > li {
    margin-right: 1rem;
    display: inline
}

.footer-content {
    color: white
}